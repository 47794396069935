import React from 'react';
import Header from '../Components/header';
import Dashboard from '../Components/dashboard';
import Footer from '../Components/footer';

function Homepage() {
  return (
    <div style={{ minHeight: '100vh', backgroundColor: "#f6f4ec"}}>
      <Header />
      <Dashboard />
      <Footer />
    </div>
  );
}

export default Homepage;
