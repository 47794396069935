import { Card, Container, Row, Col } from 'react-bootstrap';
import '../index.css';
import React, { useEffect, useState } from 'react';
import { LineChart, CartesianGrid, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts';
import { getFunctions, httpsCallable } from "firebase/functions";
import "../firebase"

const Dashboard = () => {
   const [arrayData, setArrayData] = useState([]);
   const [weeklyActiveUsers, setWeeklyActiveUsers] = useState(0);
   const [monthlyActiveUsers, setMonthlyActiveUsers] = useState(0);
   const [totalUsers, setTotalUsers] = useState(0);
   const [loading, setLoading] = useState(true); // Add a loading state

   const callFirebaseFunction = async () => {
    const functions = getFunctions();
    const getAnalytics = httpsCallable(functions, 'getAnalytics');
    getAnalytics()
      .then((result) => {
        console.log(result.data ); // Accessing the actual data
        setArrayData(result.data.WAUsWeekly)
        setWeeklyActiveUsers(result.data.WAUs)
        setMonthlyActiveUsers(result.data.MAUs)
        setTotalUsers(result.data.totalUsers)
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Error calling Firebase function:", error);
      });
  };

   useEffect(() => {
     callFirebaseFunction();
   }, []);

  return (
    <Container  style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      
      

        
        <Row className="w-100">
          <h2 style={{textAlign: 'center'}}>We take building in public <strong>seriously.</strong> Here's our real time growth stats:</h2>
          <hr className="my-4" />
          {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
          <>
          <Col xs={12} md={6}>
            <Card className="h-100" >
              <Card.Body>
                <h5 style={{textAlign: 'center'}}>🚀 Weekly Active Users by Week 🚀</h5>
                <div style={{ width: '100%', height: '100%' }}>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={arrayData.sort((a, b) => new Date(a[0]) - new Date(b[0]))}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="0" tickFormatter={(dateString) => new Date(dateString).toLocaleDateString()} />
                      <YAxis />
                      <Line type="monotone" dataKey="1" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="h-100">
              <Card.Body className="d-flex flex-column justify-content-around align-items-center">
                <h3><strong>👤 Weekly Active Users: <span style={{color: 'green'}}> {weeklyActiveUsers}</span></strong></h3>
             
                <h3><strong>👥 Monthly Active Users: <span style={{color: 'green'}}> {monthlyActiveUsers}</span></strong></h3>
   
                <h3><strong>👥👥 Total Lifetime Users: <span style={{color: 'green'}}> {totalUsers}</span></strong></h3>
              </Card.Body>
            </Card>
          </Col>
          </>)}
        </Row>
    </Container>
  );
};

export default Dashboard;
